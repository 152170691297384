import { useState } from 'react';
import classes from './notification.module.css';
import { useNavigate } from 'react-router-dom';

function NotificationSidebar({ data, setNotificationType }) {

    const [active, setActive] = useState('Create notification');
    const navigate = useNavigate()

    const links = data.map((item) => (
        <a
            className={classes.link}
            data-active={item.label === active || undefined}
            href="#"
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                setActive(item.label);
                navigate(item.link)
                setNotificationType(item.label)
            }}
        >
            <span>{item.label}</span>
        </a>
    ));

    return (
        <nav className={classes.navbar} style={{ height: "100vh" }}>
            <div className={classes.navbarMain}>{links}</div>
        </nav>
    );
}

export default NotificationSidebar;