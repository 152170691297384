import { Container, Divider, Box, Stack, Text, Tabs } from "@mantine/core"
import UserProfile from "../../components/Settings/UserProfile"
import CompanyProfile from "../../components/Settings/CompanyProfile"

const Settings = () => {
    return (
        <>
            <Container fluid style={{ height: '100%' }} >
                {/* <Group style={{ height: 'inherit' }} gap="50px" justify="flex-start" align="flex-start"> */}
                <Tabs defaultValue="userProfile" style={{ height: '100%' }} color='#E9ECEF' variant="pills" orientation="vertical"
                styles={(theme) => ({
                    height: '100%',
                    tab: {
                      color: theme.colors.dark[7], // Default text color for inactive tabs
                      '&[data-active]': {
                        color: theme.black, // Text color for active tab
                        backgroundColor: '#E9ECEF', // Active tab background color
                      },
                    },
                  })}
                >
                    <Tabs.List mt="20" radius="6">
                        <Tabs.Tab value="userProfile" p={12} fz="14px" lh="16px" fw={400} >
                            User Profile
                        </Tabs.Tab>
                        <Tabs.Tab value="companyProfile" p={12} fz="14px" lh="16px" fw={400}>
                            Company Profile
                        </Tabs.Tab>
                    </Tabs.List>


                    <Divider orientation="vertical" ml={12} mr={24} />

                    <Box w="100%" mt="20">
                        <Tabs.Panel value="userProfile">
                            <Stack>
                                <Text><strong>Your profile picture</strong></Text>
                                <UserProfile />
                            </Stack>
                        </Tabs.Panel>

                        <Tabs.Panel value="companyProfile">
                            <Stack>
                                <Text><strong>Company Logo</strong></Text>
                                <CompanyProfile />
                            </Stack>
                        </Tabs.Panel>
                    </Box>
                </Tabs>
            </Container>
        </>
    )
}

export default Settings