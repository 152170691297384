import React, { useState, useEffect } from 'react';
import { Slider } from '@mantine/core';
import { LuUser } from 'react-icons/lu';
import { IoMdImage } from "react-icons/io";

const ImageResizer = ({ image, setFile }) => {
    const [zoom, setZoom] = useState(1);
    const [selectedFile, setSelectedFile] = useState(image);
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        if (image) {
            setSelectedFile(image)
            setImageSrc(URL.createObjectURL(image))
        }
        else {
            setSelectedFile(null)
            setImageSrc('')
        }
    }, [image])

    const handleZoomChange = (value) => {
        const newZoom = 1 + (value / 100);
        setZoom(newZoom);
        handleUpload()
    };

    const handleUpload = () => {
        if (!selectedFile) return;
        const canvas = document.createElement('canvas');
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            // const size = img.width * zoom;
            const width = 243
            const height = 262

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d')

            ctx.translate(width / 2, height / 2);
            ctx.scale(zoom, zoom);
            ctx.translate(-width / 2, -height / 2);
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], selectedFile.name, {
                    type: selectedFile.type,
                    lastModified: Date.now(),
                });
                setFile(resizedFile)
            }, selectedFile.type);
        };
    };

    return (
        <div style={{ position: "relative", height: "400px", width: "243px" }}>
            {/* <input type="file" onChange={handleFileChange} style={{ marginBottom: "20px" }} /> */}

            <div style={{ width: "243px", marginBottom: "12px", height: "262px", borderRadius: "16px", border: "1px solid #3354F4", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {imageSrc ? <img
                    src={imageSrc}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "16px",
                        objectFit: "contain",
                        transform: `scale(${zoom})`,
                        transformOrigin: 'center center'
                    }}
                    alt="Selected"
                /> : <LuUser color='#3354F4' size={50} />}

            </div>

            <div style={{ display: 'flex', alignItems: "center" }}>
                <IoMdImage size={16} color="rgb(0 0 0 / 50%)" />
                <Slider
                    style={{ width: "100%", margin: "0 10px" }}
                    onChangeEnd={handleZoomChange}
                    color="blue"
                    min={1}
                    max={100}
                    label={null}
                />
                <IoMdImage size={24} color="rgb(0 0 0 / 50%)" />
                {/* </Group> */}

            </div>
            {/* <div style={{ position: "absolute", top: "calc(50% + 200px)", left: "50%", transform: "translateX(-50%)", width: "300px" }}>
                <Button onClick={handleUpload} color="blue">Upload Resized Image</Button>
            </div> */}
        </div >
    );
};

export default ImageResizer;
