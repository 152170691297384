import React, { useEffect, useState } from 'react'
import SimpleSlider from '../../components/PartnerSignup/slider.js'
import { Grid, GridCol } from "@mantine/core";
import PartnerSignUpForm from '../../components/PartnerSignup/signUpForm.js';

const PatnerSignup = () => {
    const [isMobile, setIsMobile] = useState(false)
    useEffect(()=>{
        window.addEventListener('resize', function(event) {
            if (window.innerWidth <= 786 ) { 
                setIsMobile(true)
            }
            else {
                setIsMobile(false)
            }
        }, true);
    },[])
    return (
        <div>
            <Grid h="100vh" px={'32px'}>
                {
                    isMobile ? 
                    <></>
                    :
                    <><Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 6 }} h="100vh" py={'32px'} px={0}> <SimpleSlider /> </Grid.Col></>
                }
                <Grid.Col span={{ base: 12, sm: 6, md: 6, lg: 6 }} h="100vh" py={'32px'} px={40}> <PartnerSignUpForm /> </Grid.Col>
            </Grid>
        </div>

    )
}

export default PatnerSignup 