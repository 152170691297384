import React from 'react'
import mock from '../../assets/images/iPhone 13.png'

const PushNotification = () => {
    return (
        <div style={{ display: "block", margin: "auto", textAlign:"center" }}>
            <img src={mock} alt="" />
        </div>
    )
}

export default PushNotification