import { useForm } from '@mantine/form';
import { Box, TextInput, Text, Group, Button, Divider, Stack, Loader, PasswordInput, Image, Flex, Card, Progress, Select } from '@mantine/core';
import { Dropzone, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import { GoogleButton } from '../Button/Button';
import whiteLogo from '../../assets/icons/white-logo.svg'
import classes from '../Form/form.module.css'
import { FaRegTrashAlt } from "react-icons/fa";
import { useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline, } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import onboarding from "../../assets/images/onboarding-finish.svg"
import uploadDocumentIcon from "../../assets/icons/upload-document.svg"
import excelLogo from "../../assets/icons/excel-logo.svg"
import { useSelector, useDispatch } from 'react-redux';
import ImageResizer from '../ImageResizer/ImageResizer';
import { createOrganization, updateOrganization } from "../../middlewares/organization/organization"
import { uploadEmlpoyeeTemplate } from "../../middlewares/employee/employee.service"
import { updateUserData, asyncRegister } from "../../middlewares/auth/auth"
import { ORGANIZATION_SIZE, EMPLOYEES_AT_SITE_SIZE } from "../../choices/organizationChoices"
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from '../../middlewares/auth/auth';
import { notifications } from '@mantine/notifications';
import ProfileImageUploader from "../BaseComponents/ProfileImageUploader"

function formatFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
}

export function AuthenticationForm({ step, setStep, formFields, setFormFields, setUser }) {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            email: formFields.email,
            password1: formFields.password1,
            password2: formFields.password2
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password1: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
            password2: (val) => (val === form.values.password1 ? null : 'Passwords do not match')
        },
    });

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().errors.email || form.validate().errors.password1 || form.validate().errors.password2) {
            form.validate()
        }
        else {
            setLoading((loading) => loading = true)
            const res = await dispatch(asyncRegister(form.values))
            if (asyncRegister.fulfilled.match(res)) {
                setFormFields({ ...form.values })
                setLoading((loading) => loading = false)
                setStep((step) => step + 1);
            }
            else if (asyncRegister.rejected.match(res)) {
                setLoading((loading) => loading = false)
                const errorResponse = res?.payload?.response?.data
                let message = 'Error occurred registering user'
                if (errorResponse && errorResponse?.error?.details?.email) {
                    message = errorResponse?.error?.details?.email[0]
                } else if (errorResponse && errorResponse?.error?.details?.non_field_errors) {
                    message = errorResponse?.error?.details?.non_field_errors[0]
                }
                notifications.show({ message: message, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });

            }
        }
    }

    const login = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(googleLogin({ access_token: googleRes.access_token }))
            if (googleLogin.fulfilled.match(res)) {
                const user = res.payload.user
                setUser({
                    name: user?.profile.name,
                    age: user?.profile.age ? user.age : '',
                    image: null,
                    unFormatted: null
                })
                setStep((step) => step + 2)
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    return (
        <Box radius="md" p="xl" mb={50} style={{ width: "100%", maxWidth: "32rem" }}>
            <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" />
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Create your business account</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                <Stack gap={20}>

                    <TextInput
                        label="Email"
                        placeholder="hello@refraxtion.com"
                        value={form.values.email}
                        required={true}
                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                        error={form.errors.email && 'Please enter a valid email'}
                        radius="md"
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    < PasswordInput
                        key="password"
                        label="Password"
                        placeholder="Set Password"
                        required={true}
                        value={form.values.password1}
                        error={form.errors.password1}
                        onChange={(event) => form.setFieldValue('password1', event.currentTarget.value)}
                        visibilityToggleIcon={({ reveal }) =>
                            reveal ? (
                                <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            ) : (
                                <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            )
                        }
                        radius="md"
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />

                    < PasswordInput
                        key="conform_password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        required={true}
                        value={form.values.password2}
                        onChange={(event) => form.setFieldValue('password2', event.currentTarget.value)}
                        error={form.errors.password2}
                        visibilityToggleIcon={({ reveal }) =>
                            reveal ? (
                                <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            ) : (
                                <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            )
                        }
                        radius="md"
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                </Stack>
                <Button disabled={loading} type="submit" variant="filled" fullWidth className={classes.loginBtn}>
                    <Group spacing="xs" align='flex-end'>
                        <span>Continue</span>
                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                    </Group>
                </Button>
                <Divider label="Or" labelPosition="center" my="lg" style={{ margin: "20px 0" }} />
                <Group grow mb="md" mt="md">
                    <GoogleButton style={{ border: "1px solid #CED4DA", borderRadius: "8px", boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)", fontSize: "16px", lineHeight: "20px", color: "#696969", fontWeight: "500", height: "48px" }} bg="white" onClick={login}>Continue with Google</GoogleButton>
                </Group>
            </form>
        </Box>
    );
}

export function AuthenticationForm3({ setStep, formFields, setFormFields }) {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const form = useForm({
        initialValues: {
            name: formFields.name,
            age: formFields.age,
            unFormatted: formFields.unFormatted,
            image: formFields.image
        },
        validate: {
            name: (val) => (/^[A-Za-z][A-Za-z\s-]*[A-Za-z]$/.test(val) ? null : 'Please enter a valid name.'),
            age: (val) => (/^\d+$/.test(val) ? null : 'Please enter valid age.'),
        },
    });

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().errors.name || form.validate().errors.age) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key !== 'unFormatted' && key !== 'image')
                    formData.append(key, value)
                else if (key === 'image')
                    if (value)
                        formData.append(key, value)
            })

            setLoading((loading) => loading = true)
            const res = await dispatch(updateUserData(formData))
            if (updateUserData.fulfilled.match(res)) {
                setFormFields(form.values)
                setLoading((loading) => loading = false)
                setStep((step) => step + 1);
            }
            else {
                setLoading((loading) => loading = false)
            }
        }
    }

    function setResizedImage(file) {
        form.setFieldValue('image', file)
    }

    return (
        <Box radius="md" p="xl" mb={50} style={{ width: "90%", maxWidth: "755px" }}>
            <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" />
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={600} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Enter your personal info</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "40px", }}>
                <div style={{ display: "flex", gap: "40px" }}>
                    <ImageResizer image={form.values.unFormatted} setFile={setResizedImage} />
                    <Stack style={{ width: "calc(100% - 243px" }} gap={20}>

                        <Text size="lg" fw={600} style={{ fontSize: "16px", lineHeight: "18px", color: "#000000", marginBottom: "-12px" }}>Your profile picture</Text>

                        <ProfileImageUploader profileImageURL={form.values.unFormatted ? URL.createObjectURL(form.values.unFormatted) : null} updateImage={(image) => { form.setFieldValue('image', image); form.setFieldValue('unFormatted', image) }} deleteImage={() => { form.setFieldValue('unFormatted', null); form.setFieldValue('image', null) }} />

                        <TextInput
                            label="Enter your name"
                            placeholder="name"
                            value={form.values.name}
                            required={true}
                            onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                            error={form.errors.name && 'Please enter a valid name.'}
                            radius="md"
                            styles={{
                                label: {
                                    fontSize: '16px',
                                    marginBottom: "10px",
                                    lineHeight: "18px",
                                    fontWeight: "600",
                                    color: "#424242"
                                },
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px",
                                    lineHeight: "16px",
                                    borderRadius: "6px",
                                },
                                error: {
                                    fontSize: '14px',
                                    marginTop: "5px",
                                    lineHeight: "16px",
                                },
                                required: {
                                    color: '#3354F4'
                                }
                            }}
                        />

                        <TextInput
                            label="Enter your age"
                            placeholder="0"
                            value={form.values.age}
                            required={true}
                            onChange={(event) => form.setFieldValue('age', event.currentTarget.value)}
                            error={form.errors.age && 'Please enter valid age.'}
                            radius="md"
                            styles={{
                                label: {
                                    fontSize: '16px',
                                    marginBottom: "10px",
                                    lineHeight: "18px",
                                    fontWeight: "600",
                                    color: "#424242"
                                },
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px",
                                    lineHeight: "16px",
                                    borderRadius: "6px"
                                },
                                error: {
                                    fontSize: '14px',
                                    marginTop: "5px",
                                    lineHeight: "16px",
                                },
                                required: {
                                    color: '#3354F4'
                                }
                            }}
                        />

                    </Stack>
                </div>
                <Button disabled={loading} type="submit" variant="filled" fullWidth className={classes.loginBtn} style={{ maxWidth: "408px" }}>
                    <Group spacing="xs" align='flex-end'>
                        <span>Continue</span>
                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                    </Group>
                </Button>
            </form>
        </Box>
    );
}

export function AuthenticationForm4({ setStep, formFields, setFormFields }) {

    const [uploading, setUploading] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const selectInputIcon = <IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />;

    const organization = useSelector(state => state.organizations.organization)

    const form = useForm({
        initialValues: {
            name: formFields.name,
            size: formFields.size,
            site_name: formFields.site_name,
            employees_at_site: formFields.employees_at_site,
            logo: formFields.logo
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid company name'),
            size: (val) => (/^\d{1,4} - \d{1,4}$/.test(val) ? null : 'Company Size is required'),
            employees_at_site: (val) => (/^\d{1,5} - \d{1,5}$/.test(val) ? null : 'Number of employees is required'),
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site url')
        },
    });

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().errors.name || form.validate().errors.size || form.validate().errors.employees_at_site || form.validate().errors.site_name) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === 'size') {
                    if (value)
                        formData.append(key, ORGANIZATION_SIZE[value])
                }
                if (key === 'logo') {
                    if (value)
                        formData.append(key, value)
                }
                if (key === 'name' || key === 'site_name') {
                    formData.append(key, value)
                }
                if (key === 'employees_at_site') {
                    if (value)
                        formData.append(key, EMPLOYEES_AT_SITE_SIZE[value])
                }
            })

            if (organization && organization.id) {
                setLoading((loading) => loading = true)
                const res = await dispatch(updateOrganization(formData))
                if (updateOrganization.fulfilled.match(res)) {
                    setFormFields(form.values)
                    setLoading((loading) => loading = false)
                    setStep((step) => step + 1)
                }
                else {
                    setLoading((loading) => loading = false)
                }
            } else {
                setLoading((loading) => loading = true)
                const res = await dispatch(createOrganization(formData))
                if (createOrganization.fulfilled.match(res)) {
                    setFormFields(form.values)
                    setLoading((loading) => loading = false)
                    setStep((step) => step + 1)
                }
                else {
                    setLoading((loading) => loading = false)
                }
            }

        }
    }

    return (
        <Box radius="md" p="xl" mb={50} style={{ width: "100%", maxWidth: "32.5rem" }}>
            <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" />
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Enter your company info</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                <Stack gap={20}>
                    <Text><strong>Upload logo company</strong></Text>
                    <ProfileImageUploader isCompany={true} profileImageURL={form.values.logo ? URL.createObjectURL(form.values.logo) : null} updateImage={(image) => { form.setFieldValue('logo', image) }} deleteImage={() => { form.setFieldValue('logo', null) }} />

                    <Group>
                        <TextInput
                            label="Company name"
                            placeholder="Please enter company name"
                            value={form.values.name}
                            required={true}
                            onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                            error={form.errors.name && 'Please enter a valid company name'}
                            radius="md"
                            styles={{
                                label: {
                                    fontSize: '16px',
                                    marginBottom: "10px",
                                    lineHeight: "18px",
                                    fontWeight: "600",
                                    color: "#424242"
                                },
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px",
                                    lineHeight: "16px",
                                    borderRadius: "6px",
                                },
                                error: {
                                    fontSize: '14px',
                                    marginTop: "5px",
                                    lineHeight: "16px",
                                },
                                required: {
                                    color: '#3354F4'
                                },
                                checkIconPosition: {
                                    backgroundColor: "red"
                                }
                            }}
                        />

                        <TextInput
                            label="Site name"
                            placeholder="Please enter site url"
                            value={form.values.site_name}
                            required={true}
                            onChange={(event) => form.setFieldValue('site_name', event.currentTarget.value)}
                            error={form.errors.site_name && 'Please enter a valid site url'}
                            radius="md"
                            styles={{
                                label: {
                                    fontSize: '16px',
                                    marginBottom: "10px",
                                    lineHeight: "18px",
                                    fontWeight: "600",
                                    color: "#424242"
                                },
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px",
                                    lineHeight: "16px",
                                    borderRadius: "6px",
                                },
                                error: {
                                    fontSize: '14px',
                                    marginTop: "5px",
                                    lineHeight: "16px",
                                },
                                required: {
                                    color: '#3354F4'
                                },
                                checkIconPosition: {
                                    backgroundColor: "red"
                                }
                            }}
                        />

                    </Group>
                    <Select
                        checkIconPosition="left"
                        data={['1 - 100', '100 - 500', '500 - 1500', '1500 - 2500']}
                        label="Comany size"
                        required={true}
                        error={form.errors.size && 'Please select any one choice from the drop down'}
                        onChange={(_value, option) => form.setFieldValue('size', _value)}
                        placeholder="Please select company cize"
                        rightSection={selectInputIcon}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px",
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                        defaultValue={formFields.size}
                    />
                    <Select
                        checkIconPosition="left"
                        data={['1 - 20', '20 - 40', '40 - 60', '60 - 80', '80 - 100']}
                        label="Number of employees at site (Approx)"
                        required={true}
                        error={form.errors.employees_at_site && 'Please select any one choice from the drop down'}
                        onChange={(_value, option) => form.setFieldValue('employees_at_site', _value)}
                        placeholder="Please select number of employees"
                        rightSection={selectInputIcon}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px",
                            },
                            error: {
                                fontSize: '14px',
                                marginTop: "5px",
                                lineHeight: "16px",
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                        defaultValue={formFields.employees_at_site}
                    />


                </Stack>
                <Button disabled={loading} type="submit" variant="filled" fullWidth className={classes.loginBtn}>
                    <Group spacing="xs" align='flex-end'>
                        <span>Continue</span>
                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                    </Group>
                </Button>
            </form>
        </Box>
    );
}

export function AuthenticationForm5({ setStep, formFields, setFormFields }) {

    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const form = useForm({
        initialValues: {
            file: formFields.file
        },
        validate: {
            file: (val) => (val === null ? 'File is required' : null),
        },
    });

    const startTimer = () => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 90) {
                    clearInterval(interval)
                    return prevProgress
                }
                else {
                    return prevProgress + 10
                }
            })
        }, 1000);
        return interval;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().errors.file) {
            form.validate()
        }
        else {
            const formData = new FormData()

            Object.entries(form.values).forEach(([key, value]) => {
                console.log(form.values)
                formData.append(key, value)
            })

            const timer = startTimer()
            setLoading((loading) => loading = true)
            const res = await dispatch(uploadEmlpoyeeTemplate(formData))
            if (uploadEmlpoyeeTemplate.fulfilled.match(res)) {
                clearInterval(timer)
                setFormFields(form.values)
                setLoading((loading) => loading = false)
                setProgress(100)
                setStep((step) => step + 1);
            } else if (uploadEmlpoyeeTemplate.rejected.match(res)) {
                setLoading((loading) => loading = false)
                notifications.show({ message: "Error uploading file", autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                clearInterval(timer)
                setProgress(0)
            }

        }
    }

    const setFiles = (file) => {
        file ? form.setFieldValue('file', file[0]) : form.setFieldValue('file', null);
        setProgress(0);
        setFormFields(form.values)
    }

    return (
        <Box radius="md" p="xl" style={{ width: "100%", maxWidth: "32rem" }}>
            <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" />
            <div className={classes.formTitleContainer} style={{ marginBottom: "20px" }}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Invite your team</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", color: "#5C5F66", textAlign: "center", }}>To invite employees, download the employee upload template, fill it out, and upload it to this form.</Text>
            </div>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <div style={{ marginBottom: "15px", backgroundColor: "#fff" }}>
                        <div className={classes.uploadDocument}>
                            <Dropzone
                                onDrop={setFiles}
                                onReject={(files) => console.log('rejected files', files)}
                                maxSize={80 * 1024 ** 2}
                                accept={MS_EXCEL_MIME_TYPE}
                            >
                                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                                    <Stack>
                                        <Dropzone.Idle mx="auto" >
                                            <Image src={uploadDocumentIcon} w={90} fit="contain" />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text c="#5C5F66" size="14px" inline>
                                                <strong>Drag and drop</strong> files here <strong> or click here</strong>
                                            </Text>
                                            <Text c="#5C5F66" size="14px" inline mt={7}>
                                                and select a file from your computer
                                            </Text>
                                            <Text c="#5C5F66" size="14px" inline mt={17} ta="center">
                                                Max size - 80MB <span style={{ display: "inline-block", margin: "4px 5px", width: "4px", height: "4px", borderRadius: "100%", backgroundColor: "#5C5F66" }}></span> Format: xlsx
                                            </Text>
                                        </div>
                                    </Stack>
                                </Group>
                            </Dropzone>
                        </div>
                        <div>
                            {form.values.file ?
                                <Card withBorder={true} mt="15" radius="8" p={12}>
                                    <Stack>
                                        <Flex
                                            justify="flex-start"
                                            align="center"
                                        >
                                            <Group justify="flex-start" gap="6px">
                                                <Image src={excelLogo} w={36} h={36} fit="contain"></Image>
                                                <Stack>
                                                    <Text size="10px" mb={-6}><strong>{form.values.file.name}</strong></Text>
                                                    <Text size="9px" c="dimmed"><strong>{formatFileSize(form.values.file.size)}</strong></Text>
                                                </Stack>
                                            </Group>
                                            <Group ml="auto">
                                                <Button variant="filled" color="rgba(241, 243, 245)" className={classes.uploadedFileActionButton} onClick={() => { setFiles(null) }} >
                                                    <FaRegTrashAlt color='#000' size={14} />
                                                </Button>
                                            </Group>
                                        </Flex>
                                    </Stack>
                                    <Progress mt="10" mb="" value={progress}></Progress>
                                </Card> :
                                <></>
                            }

                        </div>
                    </div>
                </Stack>
                <Button disabled={loading} variant="light" type="submit" fullWidth className={classes.uploadDocumentImageButton} color="#3354F4">
                    <Group spacing="xs" align='flex-end'>
                        <span>Employee Upload Template</span>
                        {loading && <Loader size="sm" color="#b0b6bd" type='dots' />}
                    </Group>
                </Button>
            </form>
        </Box>
    );
}

export function AuthenticationForm6() {

    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            email: '',
            name: '',
            password: '',
            terms: true,
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <Box radius="md" p="xl" style={{ width: "100%", maxWidth: "32rem" }}>
            <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" />
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Welcome to RFXN!</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                <Image src={onboarding} w={248} style={{ margin: "40px auto" }} />
            </div>
            <form onSubmit={form.onSubmit(() => { })}>
                <Button variant="filled" fullWidth className={classes.loginBtn} onClick={() => { navigate('/admin/dashboard') }}>Go to dashboard</Button>
            </form>
        </Box>
    );
}