import React, { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import { Outlet } from 'react-router-dom';
import LayoutContainer from './components/Container';
import { fetchOrganizations } from './middlewares/organization/organization';
import { fetchUser } from './middlewares/auth/auth';
import { useDispatch } from 'react-redux';

const RootLayout = () => {
    const dispatch = useDispatch()
    const [width, setWidth] = useState(window.innerWidth);

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchOrganizations())
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ display: "flex", position: "relative", height: "100%", overflow: 'hidden' }}>
            <div style={{ width: "260px", display: width < 991 ? "none" : "flex" }}>
                <Sidebar />
            </div>
            <div style={{ width: width < 991 ? "100%" : "calc(100% - 260px)", overflowY: 'auto' }}>
                <Header />
                <LayoutContainer>
                    <Outlet />
                </LayoutContainer>
            </div>
        </div>
    )
}

export default RootLayout;