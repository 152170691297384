import React, { useEffect, useState } from 'react';
import NotificationSidebar from '../../components/Notification/notificationSidebar';
import { IconBellRinging, IconReceipt2 } from '@tabler/icons-react';
import OrderManagement from '../../components/Billing/OrderManagement';
import PaymentMethod from '../../components/Billing/PaymentMethod';
import Invoice from '../../components/Billing/Invoice';
import TaxDetails from '../../components/Billing/TaxDetails';
// import SideDrawer from '../../components/Drawer/Drawer';
// import billingClass from './EmployeeManagement.module.css'

const Billing = () => {

    const data = [
        { label: 'Orders management', icon: IconBellRinging },
        { label: 'Payment method', icon: IconReceipt2 },
        { label: 'Invoicing & Billing', icon: IconReceipt2 },  
    ];

    const [width, setWidth] = useState(window.innerWidth);
    const [notificationType, setNotificationType] = useState("Orders management");

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log(width);

    return (
        <>
            {/* <ModalComp
                open={openScheduleNotification}
                setOpen={setOpenScheduleNotification}
                title={<p style={{
                    color: "#0D2029",
                    fontSize: "16px",
                    fontWeight: "700"
                }}>Scheduled push</p>}
            >
                <div style={{
                    borderTop: "1px solid #E3E3E3",
                    borderBottom: "1px solid #E3E3E3",
                    padding: "12px"
                }}>
                    <p style={{
                        color: "#5C5F66",
                        fontSize: "14px",
                        fontWeight: "600"
                    }}>TUESDAY</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px"
                    }}>
                        <p style={{
                            color: "#2C2E33",
                            fontSize: "16px",
                            fontWeight: "700"
                        }}>Feb 13th, 2024 / 1:00 PM</p>
                        <p style={{
                            color: "#5C5F66",
                            fontSize: "12px",
                            fontWeight: "400"
                        }}>GMT+8:00 (PST)</p>
                    </div>
                </div>
                <div style={{
                    marginTop: "24px"
                }}>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Text color="#424242" fw={600} mb={5}>Date</Text>
                            <DatePicker />
                        </div>
                        <div>
                            <Text color="#424242" fw={600} mb={5}>Time</Text>
                            <DatePicker />
                        </div>
                    </div>
                    <div style={{ marginTop: "8px" }}>
                        <Text color="#424242" fw={600} mb={5}>Headline</Text>
                        <Input placeholder="Notification Headline" />
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "24px"
                    }}>
                        <Button onClick={() => {
                            setOpenScheduleNotification(false)
                            setApplyNotificationSetting(true)
                        }} style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "8px",
                            marginLeft: "10px"
                        }}>Schedule your push</Button>
                    </div>
                </div>
            </ModalComp> */}
            {/* <SideDrawer title={
                <>
                    <div className={classes.drawerTitleContainer}>
                        <div>
                            <p style={{
                                fontSize: "20px",
                                fontWeight: "700",
                                color: "#0B1039"
                            }}>Order information</p>
                        </div>
                        <div>
                            <Button id={classes.filterButton}>Cancel</Button>
                        </div>
                    </div>
                    <div className={classes.border}></div>
                </>
            } open={openAddEmployeeDrawer} setOpen={setOpenAddEmployeeDrawer} /> */}
            <div style={{ overflow: 'hidden' }}>
                <div style={{ display: "flex", flexDirection: width < 991 ? "column" : "row", width: "100%" }}>
                    <div style={{ height: "100%", display: width < 991 && "none", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0 }}>
                        <NotificationSidebar data={data} setNotificationType={setNotificationType} />
                    </div>
                    {notificationType === "Orders management" && (
                        <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                            <OrderManagement />
                        </div>
                    )}
                    {notificationType === "Payment method" && (
                        <>
                            <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                                <PaymentMethod width={width} />
                            </div>
                            <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                                <TaxDetails />
                            </div>
                        </>
                    )}
                    {notificationType === "Invoicing & Billing" && (
                        <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                            <Invoice width={width} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Billing;