import React from 'react';
import classes from './notification.module.css';
import applicationNotification from '../../assets/icons/notification-type1.svg'
import emailNotification from '../../assets/icons/notification-type2.svg'
import { Button, Select, Text, Textarea } from '@mantine/core';
import { ButtonComp } from '../Button/Button';

const CreatePushNotification = ({ setOpenScheduleNotification, notificationSelected, setNotificationSelected }) => {
    return (
        <div className={classes.createPushNotificationContainer}>
            <p className={classes.title}>Create your push notification</p>
            <p className={classes.notificationType}>Notification type</p>
            <div className={classes.notificationTypeContainer}>
                <div onClick={() => setNotificationSelected("application")} style={{ borderRadius: "6px", border: notificationSelected === "application" ? "2px solid #0099FF" : "2px solid #CED4DE", backgroundColor: notificationSelected === "application" ? "#EAF1F5" : "" }} className={classes.application}>
                    <img src={applicationNotification} alt="" />
                    <p className={classes.applicationTitle}>Application</p>
                </div>
                <div onClick={() => setNotificationSelected("email")} style={{ borderRadius: "6px", border: notificationSelected === "email" ? "2px solid #0099FF" : "2px solid #CED4DE", backgroundColor: notificationSelected === "email" ? "#EAF1F5" : "", marginLeft: "10px" }} className={classes.email}>
                    <img src={emailNotification} alt="" />
                    <p className={classes.emailTitle}>Email</p>
                </div>
            </div>
            <div className={classes.createPushNotificationForm}>
                <div className={classes.inputContainer}>
                    <Text className={classes.label} fw={600} color={'#424242'} mb={5}>Notification template</Text>
                    <Select placeholder="Select notification template" data={['React', 'Angular', 'Vue', 'Svelte']} />
                </div>
                <div className={classes.inputContainer}>
                    <Text className={classes.label} fw={600} color={'#424242'} mb={5}>Recipients</Text>
                    <Select placeholder="Select recipients" data={['React', 'Angular', 'Vue', 'Svelte']} />
                </div>
                <div className={classes.inputContainer}>
                    <Text className={classes.label} fw={600} color={'#424242'} mb={5}>Headline</Text>
                    <Textarea rows={2} value="" radius={6} placeholder="Notification Headline" />
                </div>
                <div className={classes.inputContainer}>
                    <Text className={classes.label} fw={600} color={'#424242'} mb={5}>Message (required)</Text>
                    <Textarea rows={4} value="" radius={6} placeholder="Rush Gaming Universe is an incredibly fast growing community of Gamers, NFTs & Crypto enthusiasts where you get to play AAA Rated real money earning games." />
                </div>
                <div className={classes.sendButtonContainer}>
                    <Button style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        borderRadius: "8px"
                    }}>Send</Button>
                    <Button onClick={() => setOpenScheduleNotification(true)} style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        borderRadius: "8px",
                        marginLeft: "10px"
                    }}>Schedule your push</Button>
                </div>
            </div>
        </div>
    )
}

export default CreatePushNotification;