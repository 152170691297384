import { Stack, Text, Group } from "@mantine/core"
import { IconLayoutDashboard, IconUsers, IconBell, IconChartPie2, IconSettings2 } from "@tabler/icons-react"
import './BaseSidebar.css'
import { NavLink } from "react-router-dom"

const BaseSidebar = () => {
    const partnerNavigations = [
        {
            name: 'Dashboard',
            icon: <><IconLayoutDashboard size={16} /></>,
            link: '/partner/dashboard',
        },
        {
            name: 'Company Management',
            icon: <><IconUsers size={16} /></>,
            link: '/partner/company-management',
        },
        {
            name: 'Notifications',
            icon: <><IconBell size={16} /></>,
            link: '/partner/notifications',
        },
        {
            name: 'Reporting and Analytics',
            icon: <><IconChartPie2 size={16} /></>,
            link: '/partner/reporting-and-analytics',
        },
        {
            name: 'Settings',
            icon: <><IconSettings2 size={16} /></>,
            link: '/partner/settings',

        }
    ]

    const navigationTile = partnerNavigations.map(navigation => {
        return (
            <NavLink key={navigation.name} to={navigation.link} className={({ isActive }) =>
                isActive ? 'active' : 'navlink'
            }>
                <Group w={200} h={40} gap={8} py={12} pl={12} justify="flex-start" align="center" wrap="nowrap" >
                    {navigation.icon}
                    <Text span fz={14} fw={400} >{navigation.name}</Text>
                </Group>
            </NavLink>
        )
    })


    return (
        <>
            <Stack gap={4}>
                {navigationTile}
            </Stack>
        </>
    )
}
export default BaseSidebar