import { LuUser } from "react-icons/lu";
import { Button, FileButton, Loader, Group, Stack, Text } from "@mantine/core";
import { FaRegTrashAlt } from "react-icons/fa";
import classes from "../Form/form.module.css"
import { useState } from "react";
import profileUploadIcon from "../../assets/icons/profile-upload-icon.svg"
import { IconBuilding } from "@tabler/icons-react";

const ProfileImageUploader = ({ profileImageURL, deleteImage, updateImage, isCompany = false }) => {
    const [uploading, setUploading] = useState(false)
    const [showError, setShowError] = useState(false)

    const staticIcon = isCompany ? <IconBuilding color='rgb(51, 84, 244)' size={32} /> : <LuUser color='rgb(51, 84, 244)' size={32} />

    return (
        <>
            <Stack gap={5}>
                <div style={{ display: "flex" }}>
                    <div key='image_viewer' className={classes.uploadImg}>
                        {uploading ? <Loader color="blue" size={50} /> : !profileImageURL ? staticIcon : <img style={{ width: "80px", height: "80px", borderRadius: "8px" }} src={profileImageURL} alt="" />}
                    </div>
                    <div key='image_uploader' style={{ display: "flex", alignItems: "center" }}>
                        <FileButton onChange={(event) => {
                            setUploading(true)
                            setTimeout(() => {
                                setUploading(false)
                                const fileSize = event?.size / 1000000 ?? 0
                                if (fileSize <= 1 && fileSize > 0) {
                                    updateImage(event)
                                    setShowError(false)
                                }
                                else if (fileSize > 1)
                                    setShowError(true)
                                else
                                    setShowError(false)
                            }, 3000);
                        }} accept="image/png,image/jpeg, image/jpg">
                            {(props) => <Button size="md" style={{ borderRadius: "6px", backgroundColor: "#3354F4", height: '38px' }} {...props}>
                                <Group gap="6px" align='center' justify="center">
                                    <img src={profileUploadIcon} />
                                    <strong style={{ fontSize: '1rem' }}>Upload</strong>
                                </Group>
                            </Button>}
                        </FileButton>
                        <div className={classes.removeImage} onClick={deleteImage}>
                            <FaRegTrashAlt color='#ADB5BD' size={15} />
                        </div>
                    </div>
                </div>
                {showError ? <Text fz={12} c={'red'}>*Image size exceeds <strong>1MB</strong>.</Text> : <></>}
            </Stack>
        </>
    )
}

export default ProfileImageUploader