import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@mantine/core/styles.css';

import "../src/assets/scss/_variables.scss";
import "../src/assets/scss/_mixins.scss";
import "../src/assets/scss/_utilities.scss";
import "../src/assets/scss/_base.scss";

import { createTheme, MantineProvider } from '@mantine/core';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Notifications } from '@mantine/notifications';

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID 
const theme = createTheme({
  fontFamily: 'Roboto, sans-serif', // Use a string for a single font-family or a comma-separated list
});

root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <MantineProvider theme={theme}>
      <Notifications position="bottom-right"/>
        <App />
      </MantineProvider>
    </Provider>
  </PersistGate>
  </GoogleOAuthProvider>
);