import React, { useEffect, useState } from 'react';
import NotificationSidebar from '../../components/Notification/notificationSidebar';
import CreatePushNotification from '../../components/Notification/createPushNotification';
import PushNotification from '../../components/Notification/PushNotification';
import ModalComp from '../../components/Modal/Modal';
import { Button, Input, Text } from '@mantine/core';
import DatePicker from '../../components/DatePicker/DatePicker';
import VerifyEmail from '../../components/Notification/verifyEmail';
import ScheduleNotification from '../../components/Notification/ScheduleNotification';
import scheduleNotification from '../../assets/icons/schedule-notification.svg'
import { IconBellRinging, IconReceipt2 } from '@tabler/icons-react';

const Notification = () => {

    const data = [
        { label: 'Create notification', icon: IconBellRinging },
        { label: 'Scheduled Notifications', icon: IconReceipt2 },
        { label: 'Past Notifications', icon: IconReceipt2 },
    ];

    const [width, setWidth] = useState(window.innerWidth);
    const [notificationType, setNotificationType] = useState("Create notification");
    const [notificationSelected, setNotificationSelected] = useState("application")
    const [openScheduleNotification, setOpenScheduleNotification] = useState(false)
    const [applyNotificationSetting, setApplyNotificationSetting] = useState(false)
    const [sendNotification, setSendNotification] = useState(false)

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log(width);

    return (
        <>
            {applyNotificationSetting && (
                <ModalComp
                    open={sendNotification ? false : true}
                    title={<p style={{
                        color: "#0D2029",
                        fontSize: "16px",
                        fontWeight: "700"
                    }}>Push has been scheduled!</p>}
                >
                    <img src={scheduleNotification} width="100%" alt="" />
                    <div style={{
                        borderTop: "1px solid #E3E3E3",
                        borderBottom: "1px solid #E3E3E3",
                        padding: "12px"
                    }}>
                        <p style={{
                            color: "#5C5F66",
                            fontSize: "14px",
                            fontWeight: "600"
                        }}>TUESDAY</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "10px"
                        }}>
                            <p style={{
                                color: "#2C2E33",
                                fontSize: "16px",
                                fontWeight: "700"
                            }}>Feb 13th, 2024 / 1:00 PM</p>
                            <p style={{
                                color: "#5C5F66",
                                fontSize: "12px",
                                fontWeight: "400"
                            }}>GMT+8:00 (PST)</p>
                        </div>
                    </div>
                    <div style={{
                        marginTop: "24px"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            <Button onClick={() => {
                                setSendNotification(true)
                            }} style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "8px",
                                marginLeft: "10px"
                            }}>Dismiss</Button>
                        </div>
                    </div>
                </ModalComp>
            )}
            <ModalComp
                open={openScheduleNotification}
                setOpen={setOpenScheduleNotification}
                title={<p style={{
                    color: "#0D2029",
                    fontSize: "16px",
                    fontWeight: "700"
                }}>Scheduled push</p>}
            >
                <div style={{
                    borderTop: "1px solid #E3E3E3",
                    borderBottom: "1px solid #E3E3E3",
                    padding: "12px"
                }}>
                    <p style={{
                        color: "#5C5F66",
                        fontSize: "14px",
                        fontWeight: "600"
                    }}>TUESDAY</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px"
                    }}>
                        <p style={{
                            color: "#2C2E33",
                            fontSize: "16px",
                            fontWeight: "700"
                        }}>Feb 13th, 2024 / 1:00 PM</p>
                        <p style={{
                            color: "#5C5F66",
                            fontSize: "12px",
                            fontWeight: "400"
                        }}>GMT+8:00 (PST)</p>
                    </div>
                </div>
                <div style={{
                    marginTop: "24px"
                }}>
                    <div style={{ display: "flex" }}>
                        <div>
                            <Text color="#424242" fw={600} mb={5}>Date</Text>
                            {/* <DateInput label="Date input" placeholder="Date input" /> */}
                            <DatePicker />
                        </div>
                        <div>
                            <Text color="#424242" fw={600} mb={5}>Time</Text>
                            {/* <DateInput label="Date input" placeholder="Date input" /> */}
                            <DatePicker />
                        </div>
                    </div>
                    <div style={{ marginTop: "8px" }}>
                        <Text color="#424242" fw={600} mb={5}>Headline</Text>
                        <Input placeholder="Notification Headline" />
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "24px"
                    }}>
                        <Button onClick={() => {
                            setOpenScheduleNotification(false)
                            setApplyNotificationSetting(true)
                        }} style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "8px",
                            marginLeft: "10px"
                        }}>Schedule your push</Button>
                    </div>
                </div>
            </ModalComp>
            <div style={{ overflow: 'hidden' }}>
                <div style={{ display: "flex", flexDirection: width < 991 ? "column" : "row", width: "100%" }}>
                    <div style={{ height: "100%", display: width < 991 && "none", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0 }}>
                        <NotificationSidebar data={data} setNotificationType={setNotificationType} />
                    </div>
                    {notificationType === "Create notification" && (
                        <>
                            <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                                <CreatePushNotification
                                    setOpenScheduleNotification={setOpenScheduleNotification}
                                    notificationSelected={notificationSelected}
                                    setNotificationSelected={setNotificationSelected}
                                />
                            </div>
                            {sendNotification === true ? (
                                <div style={{ width: "100%", height: "100%", padding: "30px 34px", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                                    <VerifyEmail />
                                </div>
                            ) : (
                                <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                                    <PushNotification />
                                </div>
                            )}
                        </>
                    )}
                    {notificationType === "Scheduled Notifications" && (
                        <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                            <ScheduleNotification width={width} />
                        </div>
                    )}
                    {notificationType === "Past Notifications" && (
                        <div style={{ width: "100%", height: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : 0, }}>
                            <ScheduleNotification width={width} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Notification;