import { Navigate, Outlet } from "react-router-dom";

export const PartnerPublicRoutes = () =>{
    const isAuth = localStorage.getItem('access_token')
    return isAuth ? <Navigate to="/partner/dashboard" /> : <Outlet />
}

export const PartnerSignupRoutes = () => {
    const isAuth = localStorage.getItem('access_token')
    return isAuth ? <Outlet /> : <Navigate to="/" /> 
} 

// export default {PartnerPublicRoutes, PartnerSignupRoutes}