import { Group, Text, Box, ActionIcon } from '@mantine/core';
import classes from './header.module.css';
import { Dropdown } from '../EmployeeManagement/Dropdown';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconLogout } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

function Header() {

  const location = useLocation()
  const user = useSelector((state) => state.login.user)
  const navigate = useNavigate()

  const headerName = () => {
    if (location.pathname === '/dashboard')
      return 'Dashboard'
    else if (location.pathname.includes('/employee-management'))
      return 'Employee Management'
    else if (location.pathname.includes('/settings'))
      return 'Settings'
    else if (location.pathname.includes('notification'))
      return 'Notifications'
    else if (location.pathname.includes('safety-awards'))
      return 'Safety Awards'
    else if (location.pathname.includes('billing'))
      return 'Billing and Payments'
    else if (location.pathname.includes('reporting-analytics'))
      return 'Reporting and Analytics'
    else if (location.pathname.includes('tier-management'))
      return 'Tier Management'
  }

  const logoutUser = () => {
    localStorage.clear()
    navigate('/')
  }

  return (
    <Box pb={location.pathname === "/notification" || location.pathname === "/safety-awards" || location.pathname === "/dashboard" ? 0 : 0} style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: "1" }}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Text style={{ color: "#C1C2C5", fontWeight: "500" }}>{headerName()}</Text>
          <Group visibleFrom="sm">
            {Object.hasOwn(user, 'profile') ?
              <>
                <Text style={{ color: "#343A40", fontWeight: "500" }}>{user?.profile.name}</Text>
                <img src={user?.profile.image} alt="" style={{ widh: '32px', height: '32px' }} />
              </>
              :
              <></>
            }

            <Dropdown />
            <ActionIcon variant="transparent" color="#3354F4" onClick={() => { logoutUser() }}>
              <IconLogout style={{ width: '36px', height: '36px' }}/>
            </ActionIcon>
          </Group>
        </Group>
      </header>
    </Box>
  );
}

export default Header;