import { useState } from 'react';
import { DateInput } from '@mantine/dates';

function DatePicker() {
  const [value, setValue] = useState(null);
  return (
    <DateInput value={value} onChange={setValue} />
  );
}

export default DatePicker;