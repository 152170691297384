import { useState } from 'react';
import { Group, Code, Text } from '@mantine/core';
import {
  IconLayoutDashboard,
  IconUsers,
  IconAdjustments,
  IconBell,
  IconChartPie2,
  IconWallet,
  IconShieldHalf,
  IconSettings2
} from '@tabler/icons-react';
import classes from './sidebar.module.css';
import xImg from '../../assets/icons/x-icon.svg'
import logo from '../../assets/icons/logo-black.svg'
import headerLogo from '../../assets/icons/brightel.svg'
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sidebarImage from "../../assets/images/sidebar-footer-logo.png"


const data = [
  { link: '/admin/dashboard', label: 'Dashboard', icon: IconLayoutDashboard },
  { link: '/admin/employee-management', label: 'Employee Management', icon: IconUsers },
  { link: '/admin/tier-management', label: 'Tier Management', icon: IconAdjustments },
  { link: '/admin/notification', label: 'Notifications', icon: IconBell },
  { link: '/admin/reporting-analytics', label: 'Reporting and Analytics', icon: IconChartPie2 },
  { link: '/admin/billing', label: 'Billing and Payments', icon: IconWallet },
  { link: '/admin/safety-awards', label: 'Safety Awards', icon: IconShieldHalf },
  { link: '/admin/settings', label: 'Settings', icon: IconSettings2 },
];

function Sidebar() {

  const [active, setActive] = useState('/admin/dashboard');
  const navigate = useNavigate()
  const organization = useSelector(state => state.organizations.organization)

  const links = data.map((item) => (
    <a
      className={classes.link}
      data-active={item.label === active || undefined}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link)
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      {/* <item.icon className={classes.linkIcon}/> */}
      <span>{item.label}</span>
    </a>
  ));

  return (
    <nav className={classes.navbar} style={{ backgroundColor: "#212529", height: "100vh", position: "sticky", top: "0" }}>
      <div className={classes.header}>
        <Group gap={12} justify='center' wrap="nowrap">
          <Group gap={6} wrap='nowrap'>
            {organization?.logo ?
              <img width={23} height={23} src={organization?.logo} alt="" /> :
              <></>
            }
            <Text style={{ fontSize: "16.82px", color: "white", marginRight: "2.5rem", textTransform: 'capitalize' }}><strong>{organization?.name}</strong></Text>
          </Group>
          <IoIosArrowDown color='white' size={16} />
        </Group>
      </div>
      <div className={classes.navbarMain}>
        {links}
      </div>
      <div className={classes.footer}>
        {/* <img src={logo} alt="" />
        <img src={xImg} alt="" style={{ position: "absolute", top: "0", left: "0", width: "100%" }} /> */}
        <img src={sidebarImage} style={{width:'100%'}} />
      </div>
    </nav>
  );
}

export default Sidebar;