import { Box, Text, Image, Card, BackgroundImage, Stack, Title, TextInput, PasswordInput, Group, Button, Divider, Loader } from "@mantine/core"
import { useViewportSize } from '@mantine/hooks';
import loginBackground from "../../assets/images/login-background.svg"
import logo from "../../assets/images/login-header-logo.png";
import { useForm } from "@mantine/form";
import { yupResolver } from "@mantine/form";
import * as yup from 'yup';
import { loginUser, googleLogin, resetPassword } from "../../middlewares/auth/auth";
import { useDispatch } from "react-redux";

import { GoogleButton } from "../../components/Button/Button"
import { notifications } from "@mantine/notifications";

import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Login = () => {

    const { height, width } = useViewportSize()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loginButtonLoading, setLoginButtonLoading] = useState()
    const [forgotPassword, setForgotPassword] = useState(false)

    const loginValidation = yup.object().shape({
        email: yup.string().required("Email is required").email("Invalid email"),
        password: yup.string().required("Password is required")
    })
    const loginForm = useForm({
        initialValues: {
            email: '',
            password: ''
        },
        validate: yupResolver(loginValidation)
    })

    const inputStyles = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#393B42"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: '#F1F3F5'
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const login = async (e) => {
        const { hasErrors } = loginForm.validate()
        if (hasErrors)
            return
        else {
            setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
            const res = await dispatch(loginUser(loginForm.values))
            if (loginUser.fulfilled.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                navigate('/admin/dashboard')
            } else if (loginUser.rejected.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                let message = 'Login Unsuccessfull'
                const errorResponse = res?.payload?.response?.data
                if (errorResponse && errorResponse?.error?.details?.non_field_errors)
                    message = errorResponse?.error?.details?.non_field_errors[0]
                notifications.show({ message: message, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });

            }
        }
    }

    const loginViaGoogle = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(googleLogin({ access_token: googleRes.access_token }))
            if (googleLogin.fulfilled.match(res)) {

            }
        },
        onError: (error) => notifications.show({ message: error, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, })
    });

    const resetUserPassword = async () => {
        if (loginForm.validate().errors.email) {
            loginForm.validate()
        }
        else {
            setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
            const res = await dispatch(resetPassword({ email: loginForm.values.email }))
            if (resetPassword.fulfilled.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Mail sent successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (resetPassword.rejected.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Could not sent the email', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    }

    return (
        <Box bg={"#212529"} h={'100vh'} w={'100vw'}>
            <BackgroundImage src={loginBackground} h={'100vh'} w={'100vw'} fit="contain" px={50} pt={50} style={{overflowY: 'auto', overflowX: 'hidden'}}>
                <Stack gap={12} pos={'relative'} h={'100%'}>
                    <Image src={logo} w={200} h={51.83} />
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        forgotPassword ? resetUserPassword(e) : login(e);
                    }}>
                        <Stack>
                            <Card maw={488} mx="auto" bg={'#F1F3F5'} px={40} py={40} radius={12} mb={20}>
                                <Stack gap={30}>
                                    <Stack gap={8} key="card_header">
                                        <Title order={3} >
                                            {forgotPassword ?
                                                'Reset password'
                                                :
                                                'Log in to yout account!'
                                            }
                                        </Title>
                                        <Text c={'#5C5F66'}>
                                            {forgotPassword ?
                                                'Securing Your Account with a New Password'
                                                :
                                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                                            }
                                        </Text>
                                    </Stack>
                                    <Stack key="card_body" gap={20}>
                                        <TextInput
                                            label="Email"
                                            placeholder="Please enter your email"
                                            {...loginForm.getInputProps('email')}
                                            styles={inputStyles}
                                            required={true}
                                            size="sm"
                                        />
                                        {forgotPassword ?
                                            <></>
                                            :
                                            <Stack pos={'relative'}>
                                                <PasswordInput
                                                    label={"Password"}
                                                    placeholder="Please enter your password"
                                                    {...loginForm.getInputProps('password')}
                                                    styles={inputStyles}
                                                    required={true}
                                                    size="sm"
                                                />
                                                <a
                                                    style={{ textDecoration: 'underline', position: 'absolute', right: '0', top: '2px', color: '#5C5F66', fontSize: '12px', cursor: 'pointer' }}
                                                    onClick={() => setForgotPassword(forgotPassword => !forgotPassword)}
                                                >Forgot Password?</a>
                                            </Stack>
                                        }

                                    </Stack>
                                    {forgotPassword ?
                                        <Stack gap={20}>
                                            <Button disabled={loginButtonLoading} type='submit' size="md" h={48} bg={"#3354F4"} color={"#ffffff"} radius={6}>
                                                <Group spacing="xs" align='flex-end'>
                                                    <Text span>Reset Password</Text>
                                                    {loginButtonLoading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                                </Group>
                                            </Button>
                                            <Divider label="Or" labelPosition="center" color={'#ADB5BD'} />
                                            <Button size="md" h={48} bg={"#3354F4"} color={"#ffffff"} radius={6} onClick={()=>setForgotPassword(forgotPassword=>!forgotPassword)}>
                                                Back to login
                                            </Button>
                                        </Stack>
                                        :
                                        <Stack gap={20}>
                                            <Button disabled={loginButtonLoading} type='submit' size="md" h={48} bg={"#3354F4"} color={"#ffffff"} radius={6}>
                                                <Group spacing="xs" align='flex-end'>
                                                    <Text span>Continue</Text>
                                                    {loginButtonLoading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                                </Group>
                                            </Button>
                                            <Divider label="Or" labelPosition="center" color={'#ADB5BD'} />
                                            <Stack style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }}>
                                                <GoogleButton onClick={loginViaGoogle} radius={6} h={48} />
                                            </Stack>
                                        </Stack>
                                    }
                                </Stack>
                            </Card>
                        </Stack>
                    </form>
                    <Group
                        w={'100%'}
                        justify="center"
                        pb={50}
                        gap={0}
                        c={'#ADB5BD'}
                        style={{
                            cursor: 'pointer', marginTop: 'auto'
                        }}
                    >
                        <Text>About us</Text>
                        <Text onClick={() => navigate('/admin/signup')} mx={38}>Not registered? Sign up</Text>
                        <Text>Login as a partner</Text>
                    </Group>
                </Stack>

            </BackgroundImage>
        </Box>
    )
}

export default Login