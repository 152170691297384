import { Box, Stack, Group, Title, Text, Button, TextInput, Loader, Modal, ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import BaseLayout from "../BaseLayout";
import { useForm } from "@mantine/form";
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { addAdminToOrganizations } from "../../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadExcelFileModal from "../../Modal/UploadExcelModal/UploadExcelFileModal";
import { read, utils } from 'xlsx'
import { IconX } from "@tabler/icons-react";

const PartnerAdminAssignForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [opened, { open, close }] = useDisclosure()
    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                name: yup.string('Company name is required').required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter valid name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })

    const form = useForm({
        initialValues: {
            organizations: [
                { admin_email: '', name: '', site_name: '', company_id: '', phone_number: '' },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    const adminDetails = form.values.organizations.map((admin, index) => {
        return (
            <>
                <Group key={index} grow gap={12} wrap="nowrap" align='flex-end' preventGrowOverflow={false}>
                    <TextInput
                        label="Email admin member"
                        placeholder="Enter admin email"
                        {...form.getInputProps(`organizations.${index}.admin_email`)}
                        error={form.errors[`organizations.${index}.admin_email`]}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '20%' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                position: 'absolute',
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <TextInput
                        label="Company name"
                        placeholder="Enter company name"
                        {...form.getInputProps(`organizations.${index}.name`)}
                        error={form.errors[`organizations.${index}.name`]}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '18%' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                position: 'absolute',
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <TextInput
                        label="Site name"
                        placeholder="Enter site url"
                        {...form.getInputProps(`organizations.${index}.site_name`)}
                        error={form.errors[`organizations.${index}.site_name`]}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '20%' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                position: 'absolute',

                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <TextInput
                        label="Phone Number"
                        placeholder="Enter contact"
                        {...form.getInputProps(`organizations.${index}.phone_number`)}
                        error={form.errors[`organizations.${index}.phone_number`]}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '15.8%' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                position: 'absolute',

                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <TextInput
                        label="Company ID"
                        placeholder="Enter company id"
                        {...form.getInputProps(`organizations.${index}.company_id`)}
                        error={form.errors[`organizations.${index}.company_id`]}
                        withAsterisk
                        style={{ position: 'relative', minWidth: '15.8%' }}
                        styles={{
                            label: {
                                fontSize: '16px',
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#424242"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "6px"
                            },
                            error: {
                                position: 'absolute',
                            },
                            required: {
                                color: '#3354F4'
                            }
                        }}
                    />
                    <ActionIcon mb={6} variant="transparent" onClick={() => { form.removeListItem('organizations', index) }}>
                        <IconX size='sm' color={"#3354F4"} />
                    </ActionIcon>
                </Group>
            </>
        )
    })

    const addAnotherCompany = (organizationDetails = {}) => {
        if (organizationDetails && Object.keys(organizationDetails).length > 0) {
            organizationDetails.phone_number = String(organizationDetails.phone_number)
            form.insertListItem('organizations', organizationDetails);
        }
        else
            form.insertListItem('organizations', { admin_email: '', name: '', site_name: '', company_id: '', phone_number: '' });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { hasErrors } = await form.validate();
        if (hasErrors) {
            return
        }
        else if (form.values.organizations.length === 0) {
            notifications.show({ message: 'Please add a company', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
        else {
            setLoading(true)
            const res = await dispatch(addAdminToOrganizations(form.values))
            setLoading(false)
            if (addAdminToOrganizations.fulfilled.match(res)) {
                notifications.show({ message: 'You have registered organizations successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                navigate('/partner/dashboard')
            } else if (addAdminToOrganizations.rejected.match(res)) {
                if (Object.hasOwn(res.payload, 'response')) {
                    if (res.payload.response.data.error.message === 'Integrity Error') {
                        const duplicateEmail = res.payload.response.data.error.details.split('The email ')[1].split(" ")[0]
                        if (duplicateEmail) {
                            const index = form.values.organizations.findIndex(organization => organization.admin_email === duplicateEmail)
                            form.setFieldError(`organizations.${index}.admin_email`, "This user already exists")
                        }
                    }
                    else {
                        notifications.show({ message: "Error occured while registering organizations", autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                    }
                }
            }
        }
    }

    const uploadExcelFile = async (excelFile) => {
        try {
            const bufferedArray = await excelFile.arrayBuffer()

            const workbook = read(bufferedArray)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]] // getting the first sheet
            const data = utils.sheet_to_json(worksheet)

            if (data.length > 0) {
                const updatedOrganizations = form.values.organizations.filter(organization =>
                    Object.values(organization).some(value => value)
                );
                form.setFieldValue('organizations', updatedOrganizations);
                data.forEach((row) => { addAnotherCompany(row) })
            }

        }
        catch (err) {
            console.log("THIS IS ERROR", err)
        }

        close()
    }

    return (
        <>
            <BaseLayout>
                <UploadExcelFileModal opened={opened} close={close} onDrop={uploadExcelFile} />
                <Box bd='1px solid #0000001A' p={40} w={976}>
                    <form onSubmit={handleSubmit}>
                        <Stack gap={40}>
                            <Group justify="space-between" align="flex-start">
                                <Stack gap={12}>
                                    <Title order={3} >Send and invite to new company</Title>
                                    <Text c='dimmed'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                                </Stack>
                                <Group gap={12}>
                                    <Button variant="light" c="#3354F4" bg="#DBE4FF" w={139} h={40} onClick={() => { addAnotherCompany() }}>Add Company</Button>
                                    <Button variant="filled" bg="#099268" w={173} h={40} onClick={open}>Upload (excel/sheets)</Button>
                                </Group>
                            </Group>
                            <Stack>
                                {adminDetails}
                            </Stack>
                            <Button disabled={loading} type="submit" bg={"#3354F4"} c={'#ffff'} size="md" ml='auto'>
                                <Group wrap='nowrap'>
                                    <span>Create Company</span>
                                    {loading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </BaseLayout>
        </>
    )
}

export default PartnerAdminAssignForm