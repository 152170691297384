import React, { useEffect, useState } from 'react'
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';
import { Button } from '@mantine/core';
import PaymentCard from './PaymentCard';

// icons

import visa from '../../assets/icons/visa.svg';
import payPal from '../../assets/icons/payPal.svg';
import mastercard from '../../assets/icons/mastercard.svg';

const PaymentMethod = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState("");

  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={billingClass.paymentMethodContainer}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <p className={billingClass.title} style={{ marginBottom: 0 }}>Payment Method</p>
        <Button id={classes.outline}>Add new</Button>
      </div>
      <div style={{ marginTop: "14px" }}>
        <PaymentCard title="visa" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={visa} />
        <PaymentCard title="paypal" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={payPal} />
        <PaymentCard title="mastercard" selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} icon={mastercard} />
      </div>
    </div>
  )
}

export default PaymentMethod;