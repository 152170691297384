import React from 'react';
import billingClass from './billing.module.css';
import TaxDetailForm from './TaxDetailForm';

const TaxDetails = () => {
  return (
    <div className={billingClass.taxDetailContainer}>
      <p className={billingClass.title} style={{ marginBottom: "24px" }}>Tax details</p>
      <TaxDetailForm />
    </div>
  )
}

export default TaxDetails;