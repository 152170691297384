import { Tabs, Divider, Box } from "@mantine/core"
import { useViewportSize } from '@mantine/hooks';
import React from "react";
import './TabBaseLayout.css'
const TabBaseLayout = ({ tabList = [], children }) => {


    const { height } = useViewportSize();

    const serializedTabList = tabList.map(tab => {
        return (
            <Tabs.Tab key={tab.value} value={tab.value}>{tab.label}</Tabs.Tab>
        )
    })

    const serializedTabPanels = React.Children.map(children, (child, index) => (
        <>
            <Tabs.Panel key={tabList[index].value} value={tabList[index].value} className='panel'>
                {child}
            </Tabs.Panel>
        </>
    ))

    return (
            <Tabs
                orientation="vertical"
                defaultValue={tabList.length > 0 ? tabList[0].value : ''}
                color='#E9ECEF'
                variant="pills"
                h={height - 60}
                className='tab'
                styles={(theme) => ({
                    tab: {
                        color: theme.colors.dark[7], // Default text color for inactive tabs
                        '&[dataActive]': {
                            color: theme.black, // Text color for active tab
                            backgroundColor: '#E9ECEF', // Active tab background color
                        },
                    },
                })}
            >
                <Tabs.List px={12} pt={24}>
                    {serializedTabList}
                </Tabs.List>

                <Divider orientation="vertical" />

                {serializedTabPanels}
            </Tabs>
    )
}

export default TabBaseLayout