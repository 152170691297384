import React from 'react'
import billingClass from './billing.module.css';
import classes from '../Button/button.module.css';
import { Button, Input, Text } from '@mantine/core';

const TaxDetailForm = () => {
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Company Name</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your company name" />
                </div>
                <div className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Phone Number</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your phone number" />
                </div>
            </div>
            <div className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Recipient Email</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your email" />
            </div>
            <div className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Address</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your address" />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Zip / Postal code</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your Zip/Postal code" />
                </div>
                <div className={billingClass.inputContainer}>
                    <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Country</Text>
                    <Input value="Theresa Webb" radius={6} placeholder="Enter your country" />
                </div>
            </div>
            <div className={billingClass.inputContainer}>
                <Text className={billingClass.label} fw={600} color={'#424242'} mb={10}>Phone Number</Text>
                <Input value="Theresa Webb" radius={6} placeholder="Enter your phone number" />
            </div>
            <div style={{ textAlign: "end" }}>
                <Button id={classes.contained} style={{ marginTop: "24px" }}>Update details</Button>
            </div>
        </div>
    )
}

export default TaxDetailForm;