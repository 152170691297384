import { Flex, Box, Text, Image, Group, ActionIcon } from "@mantine/core"
import { useLocation } from "react-router-dom"
import { Dropdown } from "../EmployeeManagement/Dropdown"
import { useSelector } from 'react-redux';
import { IconLogout } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
const BaseHeader = () => {

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const user = useSelector((state) => state.login?.user)

    const headerTitle = () => {
        if (pathname === '/partner/dashboard')
            return 'Partner / Dashboard'
        else if (pathname === '/partner/company-management')
            return 'Company Management'
        else if (pathname === '/partner/notifications')
            return 'Notifications'
        else if (pathname === '/partner/reporting-and-analytics')
            return 'Reporting  and Analytics'
        else if (pathname === '/partner/settings')
            return 'Settings'
    }

    const logoutUser = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <Flex align="center" h={60} justify='space-between'>
                <Box><Text span c="#909296" fz={14}>{headerTitle()}</Text></Box>
                <Group gap={12} justify="flex-end" >
                    <Text style={{ color: "#343A40", fontWeight: "500" }}>{user?.profile?.name}</Text>
                    <Image src={user?.profile?.image} alt="" fit='contain' w={32} h={32} />
                    <Dropdown />
                    <ActionIcon variant="transparent" color="#3354F4" onClick={() => { logoutUser() }}>
                        <IconLogout style={{ width: '36px', height: '36px' }} />
                    </ActionIcon>
                </Group>
            </Flex>
        </>
    )
}
export default BaseHeader