import React, { useEffect, useState } from 'react';
import { Button, Container, Stack, TextInput, Group, Image, Text, Box, Card, Flex, Loader } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useDebouncedCallback } from '@mantine/hooks';
import { IoSearch } from "react-icons/io5";
import classes from './EmployeeManagement.module.css'
import modalClasses from '../Modal/modal.module.css'
import TableComp from '../Table/Table';
import SideDrawer from '../Drawer/Drawer';
import ModalComp from '../Modal/Modal';
import { FilterDropdown } from './Dropdown';
import { useForm } from '@mantine/form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewEmployee, fetchAllEmployees, editEmployee, deleteAEmployee, uploadEmlpoyeeTemplate } from '../../middlewares/employee/employee.service';
import { notifications } from '@mantine/notifications';
import { EMPLOYEE_TIER_WITH_ICONS, EMPLOYEE_PRESCRIPTION, EMPLOYEE_FRAME_AND_LENS, EMPLOYEE_STATUS_BLOCK, EMPLOYEE_TABLE_HEADERS, EMPLOYEE_TIER, EMPLOYEE_STATUS } from '../../choices/employee.choice';
import { updateCurrentPage } from '../../store/employess/employeesSlice';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { setEmployeeFilters, setSearchEmployeeByName } from '../../store/filters/filterSlice';
import uploadDocumentIcon from "../../assets/icons/upload-document.svg"
import excelLogo from "../../assets/icons/excel-logo.svg"

const EmployeeManagement = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
    const [openCSVModal, setOpenCSVModal] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [isEditProfile, setIsEditProfile] = useState(false)
    const [userProfileImageUrl, setUserProfileImageUrl] = useState(null)
    const [employeeCSVFile, setEmployeeCSVFile] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [fetchEmployeeLoader, setFetchEmployeeLoader] = useState(false)
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [uploadFileLoading, setUploadFileLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)

    const employees = useSelector(state => state.employee.employees)
    const filters = useSelector(state => state.filters.employeeFilters)
    const totalEmployees = useSelector(state => state.employee.totalNumberOfRecords)
    const totalPages = useSelector(state => state.employee.totalPages)
    const currentPage = useSelector(state => state.employee.currentPage)

    let { id } = useParams();
    const searchIcon = <IoSearch color="#ADB5BD" />
    const searchLoader = <Loader size='sm' color="#ADB5BD" />

    useEffect(() => {
        setEmployeeList(employees)
    }, [employees])

    useEffect(() => {
        fetchEmployees()
    }, [filters, currentPage])

    const form = useForm({

        initialValues: {
            name: '',
            ethnicity: '',
            age: '',
            email: '',
            tier: null,
            address: '',
            gender: null,
            image: '',
            status: ''
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid company name'),
            age: (val) => (/^(1[89]|[2-9][0-9]|100)$/.test(val) ? null : 'Please enter valid age'),
            gender: (val) => (/([M,F,O])/.test(val) ? null : 'Please select appropiate gender'),
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Please enter a valid email'),
            tier: (val) => (/([1-3])/.test(val) ? null : 'Please select a tier for the employee'),
        },

    })

    function updateFormValues(key, value) {
        form.setFieldValue(key, value)
        if (key === 'image') {
            setUserProfileImageUrl((userProfileImageUrl) => {
                if (value) {
                    return userProfileImageUrl = URL.createObjectURL(value)
                } else {
                    return userProfileImageUrl = null
                }
            })
        }
    }

    async function addEmployee() {
        validateEmployeeData(async (formData, form) => {
            const res = await dispatch(addNewEmployee(formData))
            if (addNewEmployee.fulfilled.match(res)) {
                setOpenAddEmployeeDrawer(false)
                setIsEditProfile(false)
                notifications.show({ message: 'Employee added successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                form.reset()
                setUserProfileImageUrl('')
            }
            else if (addNewEmployee.rejected.match(res)) {
                if (Object.hasOwn(res.payload, 'response'))
                    if (res.payload.response.data.error.message === 'Integrity Error'){
                        form.setFieldError('email', res.payload.response.data.error.details);
                    }
            }
        })
    }

    async function fetchEmployees() {
        // fetchEmployeeLoader = true
        setFetchEmployeeLoader(true)
        await dispatch(fetchAllEmployees())
        setFetchEmployeeLoader(false)
    }

    async function editEmployeeData() {
        validateEmployeeData(async (formData, form) => {
            const res = await dispatch(editEmployee({ data: formData, employeeId: id }))
            if (editEmployee.fulfilled.match(res)) {
                setOpenAddEmployeeDrawer(false)
                setIsEditProfile(false)
                notifications.show({ message: 'Employee details updated successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
            else if (editEmployee.rejected.match(res)) {
                if (Object.hasOwn(res.payload, 'response'))
                    if (res.payload.response.data.error.message === 'Integrity Error'){
                        form.setFieldError('email', res.payload.response.data.error.details);
                    }   
            }
        })
    }

    async function deleteEmployee() {
        const res = await dispatch(deleteAEmployee(id))
        if (deleteAEmployee.fulfilled.match(res)) {
            setOpenAddEmployeeDrawer(false)
            setIsEditProfile(false)
            notifications.show({ message: 'Employee delete successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            form.reset()
            setUserProfileImageUrl(null)
            navigate(`/admin/employee-management/`)
        }
        else if (deleteAEmployee.rejected.match(res)) {
            notifications.show({ message: 'Employee could not be delete', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
    }

    const searchEmployee = useDebouncedCallback(async (searchQuery) => {
        setSearchLoading(searchLoading => !searchLoading)
        dispatch(setSearchEmployeeByName(searchQuery))
        searchQuery ? setIsFilterApplied(true) : setIsFilterApplied(false)
        await fetchEmployees()
        setSearchLoading(searchLoading => !searchLoading)

    }, 1000)

    function serailizeEmployeesData(employees) {
        return employees.map((employee) => {
            const isSelected = selectedEmployees.find(selectedEmployee => selectedEmployee.id === employee.id)
            return {
                ...employee,
                tier: EMPLOYEE_TIER_WITH_ICONS[employee.tier],
                rx_non_rx: EMPLOYEE_PRESCRIPTION[employee.rx_non_rx],
                frame_and_lens: EMPLOYEE_FRAME_AND_LENS[employee.frame_and_lens],
                status: EMPLOYEE_STATUS_BLOCK[employee.status],
                checked: isSelected ? true : false,
                actions:
                    <>
                        <Group onClick={() => { openEditEmployee(employee.id) }} gap={8} justify='center' align='center'>
                            <IconEdit style={{ width: '16px', height: '16px' }} />
                            <p >Edit</p>
                        </Group>
                    </>
            }
        })
    }

    function openEditEmployee(id) {
        const employee = employeeList.find(employee => employee.id === id)
        if (employee) {
            form.setValues({
                name: employee.name,
                ethnicity: employee.ethnicity ? employee.ethnicity : '',
                age: employee.age,
                email: employee.email,
                tier: employee.tier,
                address: employee.address ? employee.address : '',
                gender: employee.gender,
                status: employee.status
            })
            setUserProfileImageUrl(employee.image)
            setOpenAddEmployeeDrawer(true)
            setIsEditProfile(true)
            navigate(`/admin/employee-management/edit/${id}`)
        }
    }

    function openAddEmployee() {
        setOpenAddEmployeeDrawer(true)
        setIsEditProfile(false)
        form.reset()
        navigate('/admin/employee-management')
    }

    function validateEmployeeData(fn) {
        const validation = form.validate()

        if (validation.errors.name || validation.errors.age || validation.errors.gender || validation.errors.email || validation.errors.tier) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key !== 'status')
                    formData.append(key, value)
            })
            return fn(formData, form)
        }
    }

    function applyFilter(filters) {
        setIsFilterApplied(true)
        if (!Object.values(filters).every(filter => filter.length === 0))
            dispatch(setEmployeeFilters(filters))
    }

    function clearFilters(filters) {
        setIsFilterApplied(false)
        dispatch(setEmployeeFilters(filters))
    }

    async function uploadEmployeesCSVFile() {
        if (employeeCSVFile) {
            setUploadFileLoading(uploadFileLoading => !uploadFileLoading)
            const formData = new FormData()
            formData.append('file', employeeCSVFile)
            const res = await dispatch(uploadEmlpoyeeTemplate(formData))
            if (uploadEmlpoyeeTemplate.fulfilled.match(res)) {
                await fetchEmployees()
                setOpenCSVModal(false)
                setEmployeeCSVFile(null)
                notifications.show({ message: "Employees updated successfully", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (uploadEmlpoyeeTemplate.rejected.match(res)) {
                notifications.show({ message: "Error uploading file", autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
            setUploadFileLoading(uploadFileLoading => !uploadFileLoading)
        } else {
            notifications.show({ message: "Please select a file to upload", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
    }

    function formatFileSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

        return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    function setExportCSVData(employee = {}, isChecked = false, selectAll = false) {
        if (!selectAll && Object.keys(employee).length > 0) {
            if (isChecked)
                setSelectedEmployees(selectedEmployees => {
                    return [...selectedEmployees, employee]
                })
            else
                setSelectedEmployees(selectedEmployees => {
                    return [...selectedEmployees.filter(selectedEmployee => selectedEmployee.id !== employee?.id)]
                })
        }
        else if (!selectAll && Object.keys(employee).length === 0) {
            setSelectedEmployees([])
        }
        else {
            setSelectedEmployees(employeeList)
        }
    }

    function exportCSV() {

        const headers = Object.values(EMPLOYEE_TABLE_HEADERS).map(headerValue => headerValue.label !== 'Actions' ? headerValue?.label : null).join(',')
        const data = selectedEmployees.map(selectedEmployee => {
            const employee = employeeList.find(employee => employee.id === selectedEmployee.id)
            if (employee)
                return Object.values(EMPLOYEE_TABLE_HEADERS).map(headerValue => {
                    if (headerValue.value === 'tier')
                        return employee[headerValue.value] ? EMPLOYEE_TIER[employee[headerValue.value]] : employee[headerValue.value]
                    else if (headerValue.value === 'rx_non_rx')
                        return employee[headerValue.value] ? EMPLOYEE_PRESCRIPTION[employee[headerValue.value]] : employee[headerValue.value]
                    else if (headerValue.value === 'status')
                        return employee[headerValue.value] ? EMPLOYEE_STATUS[employee[headerValue.value]] : employee[headerValue.value]
                    return employee[headerValue.value]
                }).join(",")

        }).join("\n")

        // Create a Blob from the CSV string
        const blob = new Blob([`${headers}\n${data}`], { type: 'text/csv' });

        // Generate a download link and initiate the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'employees.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    function changePage(page) { dispatch(updateCurrentPage(page)) }

    return (
        <>
            <ModalComp
                open={openCSVModal}
                setOpen={setOpenCSVModal}
                title={
                    <>
                        <p className={modalClasses.modalTitle}>Upload CSV</p>
                        <p className={modalClasses.modalDesc}>Description goes here.</p>
                    </>
                }
            >
                <div className={classes.modalContent}>
                    <Stack gap={6}>
                        <Box style={{ borderStyle: 'dashed', borderRadius: '8px', borderColor: '#CED4DA', borderWidth: "2px" }}>
                            <Dropzone
                                onDrop={(file) => { setEmployeeCSVFile(file ? file[0] : null) }}
                                onReject={(files) => console.log('rejected files', files)}
                                maxSize={80 * 1024 ** 2}
                                accept={[MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
                            >
                                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                                    <Stack>
                                        <Dropzone.Idle mx="auto" >
                                            <Image src={uploadDocumentIcon} w={90} fit="contain" />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text c="#5C5F66" size="14px" inline align='center'>
                                                Drag and drop file here
                                            </Text>
                                            <Text c="#5C5F66" size="14px" inline mt={7}>
                                                or <strong>select a file from your computer</strong>
                                            </Text>
                                        </div>
                                    </Stack>
                                </Group>
                            </Dropzone>
                        </Box>
                        {employeeCSVFile ?
                            <Card withBorder={true} radius="8" p={12}>
                                <Stack>
                                    <Flex
                                        justify="flex-start"
                                        align="center"
                                    >
                                        <Group justify="flex-start" gap="6px" align='center'>
                                            <Image src={excelLogo} w={36} h={36} fit="contain"></Image>
                                            <Stack gap={6}>
                                                <Text size="10px"><strong>{employeeCSVFile?.name}</strong></Text>
                                                <Text size="9px" c="dimmed"><strong>{formatFileSize(employeeCSVFile?.size)}</strong></Text>
                                            </Stack>
                                        </Group>
                                        <Button ml="auto" variant="filled" color="rgba(241, 243, 245)" onClick={() => { setEmployeeCSVFile(null) }} >
                                            <IconTrash color='#000' size={14} />
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Card>
                            :
                            <></>

                        }
                        <Button disabled={uploadFileLoading} bg="#3354F4" color="#ffffff" size="sm" radius={6} onClick={uploadEmployeesCSVFile}>
                            <Group>
                                Upload
                                {uploadFileLoading ? <Loader size="sm" color="#b0b6bd" type='dots' /> : <></>}
                            </Group>
                        </Button>
                    </Stack>

                </div>
            </ModalComp>
            <SideDrawer
                title={
                    <>
                        <div className={classes.drawerTitleContainer}>
                            <div>
                                <p style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#0B1039"
                                }}>{isEditProfile ? 'Edit employee' : 'Add employee'}</p>
                            </div>
                            <div>
                                <Button id={classes.filterButton}
                                    onClick={() => setOpenAddEmployeeDrawer(() => {
                                        setUserProfileImageUrl('')
                                        return false
                                    })}>
                                    Cancel
                                </Button>
                                {isEditProfile ?
                                    <Button id={classes.upload} onClick={editEmployeeData}>Save changes</Button>
                                    :
                                    <Button id={classes.upload} onClick={addEmployee}>Add user and invite</Button>
                                }
                            </div>
                        </div>
                        <div className={classes.border}></div>
                    </>
                }
                open={openAddEmployeeDrawer} setOpen={setOpenAddEmployeeDrawer}
                updateFormValues={updateFormValues}
                form={form}
                userProfileImageUrl={userProfileImageUrl}
                isEditProfile={isEditProfile}
                deleteEmployee={deleteEmployee}
            />
            <Container fluid style={{ height: '100%' }}>
                <Stack gap={20} style={{ height: '100%' }}>
                    <Group justify='space-between' mt={24}>
                        <Group gap={10}>
                            <TextInput
                                leftSection={searchIcon}
                                placeholder="Search"
                                className={classes.input}
                                rightSection={searchLoading ? searchLoader : ''}
                                onChange={(event) => { searchEmployee(event.target.value) }} />
                            <FilterDropdown applyFilter={applyFilter} clearFilters={clearFilters} />
                        </Group>
                        <div style={{ display: "flex" }}>
                            <Button id={classes.export} onClick={exportCSV}>Export CSV</Button>
                            <Button id={classes.upload} onClick={() => setOpenCSVModal(true)}>Upload CSV</Button>
                            <Button id={classes.addNew} onClick={() => openAddEmployee()}>Add new employee</Button>
                        </div>
                    </Group>
                    <Stack gap={120}>
                        <TableComp
                            openAddEmployeeDrawer={openAddEmployeeDrawer}
                            setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer}
                            tableHeaders={EMPLOYEE_TABLE_HEADERS}
                            data={serailizeEmployeesData(employeeList)}
                            isFilterApplied={isFilterApplied}
                            fetchEmployeeLoader={fetchEmployeeLoader}
                            setExportCSVData={setExportCSVData}
                            totalRecords={totalEmployees}
                            totalPages={totalPages}
                            changePage={changePage}
                        />
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default EmployeeManagement;