import Tab from '../Tabs/Tabs';
import { Drawer, Tabs, Grid, Select, Stack, Button, Group, Box, Text } from '@mantine/core';
import classes from './drawer.module.css'
import { IconUser, IconNews, IconStereoGlasses, IconShieldHalfFilled, IconEdit, IconReload } from '@tabler/icons-react';
import InputBox from '../BaseComponents/InputBox';
import { EMPLOYEE_TIER_CHOICES, EMPLOYEE_STATUS_BLOCK } from '../../choices/employee.choice';
import { GENDER_CHOICES } from '../../choices/common.choice';
import ProfileImageUpload from '../BaseComponents/ProfileImageUploader'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { inviteEmployee } from '../../middlewares/employee/employee.service'


function SelectInput({ data, label, value, rightIcon, placeHolder, leftIcon, error, onChange, form }) {
  return (
    <>
      <Select
        checkIconPosition="left"
        data={data}
        label={label}
        onChange={(_value, option) => { onChange(_value, option) }}
        placeholder={placeHolder}
        rightSection={rightIcon}
        leftSection={leftIcon}
        styles={{
          input: {
            marginTop: '10px',
            padding: "8px 12px",
            borderRadius: "6px",
          },
          label: {
            font: 'Roboto',
            fontSize: '14px',
            fontWeight: '600',
            color: '#495057'
          },
        }}
        error={error}
        defaultValue={value}
        {...form}
      />
    </>
  )
}

function SideDrawer({ type, open, title, setOpen, updateFormValues, form, isEditProfile, userProfileImageUrl, deleteEmployee }) {
  const tabs = [
    {
      name: 'User Info',
      icon: <IconUser size={20} />,
      indicator: false,
      value: 'userInfo',
      disabled: false
    },
    {
      name: 'Prescripition',
      icon: <IconNews size={20} />,
      indicator: false,
      value: 'prescription',
      disabled: true
    },
    {
      name: 'Frames & Lens options',
      icon: <IconStereoGlasses size={20} />,
      indicator: false,
      value: 'framesAndLensOptions',
      disabled: true
    },
    {
      name: 'Safety Awards',
      icon: <IconShieldHalfFilled size={20} />,
      indicator: false,
      value: 'safetyAwards',
      disabled: true
    }
  ]

  const editIcon = <IconEdit />

  const routeParams = useParams()
  const dispatch = useDispatch()
  const organization = useSelector(state=>state.organizations.organization)
  const employeeId = routeParams.id

  async function sendInvitation() {
    const payload = { 'employee_id': employeeId, 'organization_id': organization?.id }
    const res = await dispatch(inviteEmployee(payload))
    if (inviteEmployee.fulfilled.match(res)) {
      updateFormValues('status', '1')
    }
  }

  return (
    <>
      <Drawer padding={0} opened={open} onClose={() => setOpen(false)} withCloseButton={false} title={title} position="right" size={710}>
        <div style={{ padding: "16px 0 100px 0" }}>
          <Tab tabsArray={tabs} >
            <Tabs.Panel value="userInfo">
              <div className={classes.inputSection}>
                <Stack gap={20}>
                  <ProfileImageUpload mb={20} profileImageURL={userProfileImageUrl} updateImage={(image) => { updateFormValues('image', image) }} deleteImage={() => { updateFormValues('image', '') }} />
                  <Grid gutter={20}>
                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                      <InputBox label={"Name"} rightIcon={editIcon} value={form.values.name} placeHolder="Please enter name" error={form.errors.name} onChange={(val) => { updateFormValues('name', val) }} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                      <InputBox label={"Ethnicity"} rightIcon={editIcon} value={form.values.ethnicity} placeHolder="Please enter ethnicity" error={form.errors.ethnicity} onChange={(val) => { updateFormValues('ethnicity', val) }} />
                    </Grid.Col>
                  </Grid>
                  <Grid gutter={20}>
                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                      <InputBox label={"Age"} rightIcon={editIcon} value={form.values.age} placeHolder="Please enter age" error={form.errors.age} onChange={(val) => { updateFormValues('age', val) }} />
                    </Grid.Col>
                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                      <SelectInput
                        data={GENDER_CHOICES}
                        label="Gender"
                        value={form.values.gender}
                        defaultValue={form.values.gender}
                        rightIcon={editIcon}
                        placeHolder="Please select gender"
                        error={form.errors.gender}
                        onChange={(val) => { updateFormValues('gender', val) }}
                        form={form.getInputProps('gender')}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid >
                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                      <InputBox label={"Email"} rightIcon={editIcon} value={form.values.email} placeHolder="Please enter email" error={form.errors.email} onChange={(val) => { updateFormValues('email', val) }} />
                    </Grid.Col>
                  </Grid>
                  <Grid >
                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                      <SelectInput
                        data={EMPLOYEE_TIER_CHOICES}
                        label="Tier assignment"
                        value={form.values.tier}
                        placeHolder="Please select tier"
                        error={form.errors.tier}
                        onChange={(val) => { updateFormValues('tier', val) }}
                        form={form.getInputProps('tier')}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid >
                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                      <InputBox label={"Site/Location"} value={form.values.address} placeHolder="Please enter location" error={form.errors.address} onChange={(val) => { updateFormValues('address', val) }} />
                    </Grid.Col>
                  </Grid>
                  {isEditProfile ?
                    <>
                      <Grid >
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                          <Stack gap={10}>
                            <Text
                              style={{
                                font: 'Roboto',
                                fontSize: '14px',
                                fontWeight: '600',
                                color: '#495057'
                              }}>
                              Status
                            </Text>
                            <Group
                              justify='flex-around'
                              align="center"
                              px={12}
                              style={{
                                width: '100%',
                                height: '38px',
                                borderRadius: '6px',
                                border: '1px',
                                borderColor: '#CED4DA',
                                borderStyle: 'solid'
                              }}>
                              <Box mr='auto'>
                                {EMPLOYEE_STATUS_BLOCK[form.values.status]}
                              </Box>
                              <Group ml='auto' color="#3354F4" gap={8} justify='flex-end' onClick={sendInvitation} style={{ cursor: 'pointer' }}>
                                <IconReload color="#3354F4" style={{ width: '14px', height: '14px' }} />
                                <Text c="#3354F4" style={{ fontSize: '14px' }}>
                                  <strong>Resend invite</strong>
                                </Text>
                              </Group>
                            </Group>
                          </Stack>
                        </Grid.Col>
                      </Grid>
                      <Grid >
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                          <Group justify='flex-end' align='center'>
                            <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={deleteEmployee}>Delete User</Button>
                            <Button color="#3354F4" radius="md">Message employee</Button>
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </>
                    :
                    <></>
                  }

                </Stack>
              </div>
            </Tabs.Panel>
          </Tab>
        </div>
      </Drawer >
    </>
  );
}

export default SideDrawer;